<div [formGroup]="frmComponent" class="select-div + {{ userClass }}">
  @if (showIcon) {
    <div class="input-icons">
      <i class="fa-solid fa-user icon"></i>
      <p-select
        [id]="componentId"
        [name]="componentId"
        [attr.data-testid]="componentId"
        [formControlName]="componentId"
        [options]="itemsArray"
        (onChange)="selectionChanged()"
        [placeholder]="defaultText"
        [styleClass]="cssClasses"
        [required]="required"
      >
      </p-select>
    </div>
  }
  @if (!showIcon) {
    <div>
      <p-select
        [id]="componentId"
        [appendTo]="overlayClass ? 'body' : null"
        [name]="componentId"
        [attr.data-testid]="componentId"
        [formControlName]="componentId"
        [options]="itemsArray"
        (onChange)="selectionChanged()"
        [placeholder]="defaultText"
        [styleClass]="cssClasses"
        dropdownIcon="fa-solid fa-caret-down"
        [required]="required"
        (onShow)="onDropdownShow()"
      >
      </p-select>
    </div>
  }
  @if (loading) {
    <div class="width-15px spinner-div">
      <app-icon source="rn-circle-notch" imgClass="loading-spinner gray-icon "></app-icon>
    </div>
  }
  @if (component.touched) {
    <div>
      @if (component.errors?.required) {
        <div class="help-block">Required field</div>
      }
    </div>
  }
</div>
