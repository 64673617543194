<div class="modal-content">
  <div class="modal-header no-border dspy-block">
    <div>
      <button type="button" class="close pullUpperRight" (click)="cancel()" aria-hidden="true">
        <span uib-tooltip="Close" style="font-size: 30px; font-weight: 200" tooltipPosition="top" pTooltip="Close"
          >×</span
        >
      </button>
    </div>
    <div class="justifiedTitlebar">
      <h3 class="header-black">{{ actionText }} License</h3>
    </div>
    <div class="row ownerShipRow">
      <div class="form-group col-sm-12 ownerShipDivision">
        <label for="source">Ownership:&nbsp;</label>
        <span>BYOL</span>
      </div>
    </div>
    <hr class="headerSeperator" />
  </div>
  <div class="modal-body padding-20-all">
    <form #licenseForm="ngForm" name="licenseForm">
      <div class="row">
        <div
          class="form-group col-sm-12"
          [ngClass]="{
            'has-error': yearControl?.errors && yearControl?.touched
          }"
        >
          <label for="year">Year</label>
          <p-select
            data-testid="year"
            id="year"
            name="year"
            dropdownIcon="fa-solid fa-caret-down"
            #yearControl="ngModel"
            [options]="yearsSelect"
            [(ngModel)]="year"
            (onChange)="versionChange()"
            required
          >
          </p-select>
          @if (!licenseForm.valid && yearControl.errors && yearControl.touched) {
            <div>
              @if (yearControl.errors?.required) {
                <div class="help-block">Required field</div>
              }
            </div>
          }
        </div>
      </div>
      <div class="row">
        <div
          class="form-group col-sm-12"
          [ngClass]="{
            'has-error': editionControl?.errors && editionControl?.touched
          }"
        >
          <label for="edition">Edition</label>
          <p-select
            data-testid="editionSelect"
            id="editionSelect"
            name="editionSelect"
            dropdownIcon="fa-solid fa-caret-down"
            #editionControl="ngModel"
            [options]="editionsSelect"
            [(ngModel)]="edition"
            required
          >
          </p-select>
          @if (!licenseForm.valid && editionControl?.errors && editionControl?.touched) {
            <div>
              @if (editionControl?.errors?.required) {
                <div class="help-block">Required field</div>
              }
            </div>
          }
        </div>
      </div>
      <div class="row">
        <div
          class="form-group col-sm-9"
          [ngClass]="{
            'has-error': countryControl.errors && countryControl.touched
          }"
        >
          <label for="country">Country</label>
          <div class="p-formgroup-inline">
            <div class="p-field-checkbox">
              <input
                type="radio"
                data-testid="countrySelectUS"
                id="countrySelectUS"
                name="countryUS"
                #countryControl="ngModel"
                [(ngModel)]="country"
                value="US"
                (change)="countryChange()"
                required
              />US
            </div>
            <div class="p-field-checkbox">
              <input
                type="radio"
                data-testid="countrySelectCan"
                id="countrySelectCan"
                name="countryCan"
                #countryControl="ngModel"
                [(ngModel)]="country"
                value="CA"
                (change)="countryChange()"
                required
              />Canada
            </div>
          </div>
          @if (!licenseForm.valid && countryControl.errors && countryControl.touched) {
            <div>
              @if (countryControl.errors.required) {
                <div class="help-block">Required field</div>
              }
            </div>
          }
        </div>
      </div>
      @if (canShowQuantity) {
        <div class="row">
          <div class="form-group col-sm-9">
            <span>No. of Users</span>
            <app-quantity-control
              id="licenseQuantity"
              [MinValue]="minQuantity"
              [MaxRemaining]="maxQuantity"
              [DefaultValue]="licenseQuantity"
              [DisallowInput]="false"
            ></app-quantity-control>
          </div>
        </div>
      }
      @if (canShowLicenseKeyAndProductNumber) {
        <div
          class="row"
          [ngClass]="{
            'has-error': licenseKeyEdit.errors && licenseKeyEdit.touched
          }"
        >
          <div class="form-group col-sm-12">
            <label for="licenseKey">License Key</label>
            <input
              data-testid="licenseKeyEdit"
              id="licenseKeyEdit"
              class="form-control"
              #licenseKeyEdit="ngModel"
              name="licenseKeyEdit"
              [(ngModel)]="licenseKey"
              matInput
              [mask]="licenseKeyMask"
              type="tel"
              placeholder="QuickBooks License Number"
              (focus)="$event.target.select()"
              required
              minlength="15"
            />
            @if (!licenseForm.valid && licenseKeyEdit.errors && licenseKeyEdit.touched) {
              <div>
                @if (licenseKeyEdit.errors.required || licenseKeyEdit.errors.pattern) {
                  <div class="help-block">Required field</div>
                }
              </div>
            }
          </div>
        </div>
      }
      @if (canShowLicenseKeyAndProductNumber) {
        <div
          class="row"
          [ngClass]="{
            'has-error': productNumEdit.errors && productNumEdit.touched
          }"
        >
          <div class="form-group col-sm-12">
            <label for="productNumber">Product Number</label>
            <input
              data-testid="productNumberEdit"
              id="productNumberEdit"
              #productNumEdit="ngModel"
              name="productNumberEdit"
              [(ngModel)]="productNumber"
              matInput
              [mask]="productNumberMask"
              type="tel"
              placeholder="QuickBooks Product Number"
              class="form-control"
              minlength="6"
              (focus)="$event.target.select()"
              required
            />
            @if (!licenseForm.valid && productNumEdit.errors && productNumEdit.touched) {
              <div>
                @if (productNumEdit.errors.required || productNumEdit.errors.pattern) {
                  <div class="help-block">Required field</div>
                }
              </div>
            }
          </div>
        </div>
      }
      @if (canShowIsProAdvisor) {
        <div class="row">
          <div class="form-group col-sm-12">
            <label for="isProAdvisor">
              <input type="checkbox" name="isProAdvisor" id="isProAdvisor" [(ngModel)]="isProAdvisor" />
              This is a QuickBooks ProAdvisor license
              <app-icon
                source="rn-help"
                pTooltip="ProAdvisor is an Intuit program for certified super users"
              ></app-icon>
            </label>
          </div>
        </div>
      }
      @if (canShowIsAutoAssignable) {
        <div class="row">
          <div class="form-group col-sm-12">
            <label class="checkboxForAutomatically" for="isAutoAssignable">
              <p-checkbox
                data-testid="isAutoAssignable"
                name="isAutoAssignable"
                id="isAutoAssignable"
                [(ngModel)]="isAutoAssignable"
                binary="true"
              ></p-checkbox>
              Automatically assign license to users when they install QuickBooks.
            </label>
          </div>
        </div>
      }
    </form>
  </div>
  <div class="modal-footer">
    <app-button
      data-testid="cancelBtn"
      id="cancelLink"
      label="CANCEL"
      buttonStyle="addUserTeritary"
      (clickActionEmitter)="cancel()"
    >
    </app-button>
    <app-button
      data-testid="saveBtn"
      id="submitBtn"
      [disabled]="!licenseForm.valid"
      label="SAVE"
      showProcessing="true"
      [processing]="processing"
      buttonStyle="primary"
      (clickActionEmitter)="submitChanges()"
    >
    </app-button>
  </div>
</div>
