<ng-container [formGroup]="parentForm">
  <div class="row">
    <div class="col-12">
      <label for="country">Country</label><br />
      <p-select
        [attr.disabled]="isReadOnly"
        dropdownIcon="fa-solid fa-caret-down"
        id="country"
        name="country"
        formControlName="country"
        [options]="countries"
        optionLabel="value"
        (keydown)="onDropdownKeydown($event)"
        (onChange)="onChangeCountry(false, $event)"
        panelStyleClass="highlight-yellow"
        class="width-100-percent withoutMarginDropdown"
      ></p-select>
    </div>
  </div>

  <div class="row p-mt-3">
    <div class="col-12">
      <div
        class="p-field"
        [ngClass]="{
          'has-error': !address1?.valid && (submitted || address1?.touched)
        }"
      >
        <label for="address1">Street Address</label><br />
        <input
          [readonly]="isReadOnly"
          appTrimInput
          id="address1"
          name="address1"
          formControlName="address1"
          class="ui-inputtext form-control"
          placeholder="Street Address"
          pInputText
          maxlength="255"
          (focus)="$event.target.select()"
          data-testid="businessAddressAddress1"
        />
        @if (submitted || address1?.touched) {
          <div>
            @if (address1?.errors?.required || address1?.errors?.whitespace) {
              <div class="help-block pt-1">Required field</div>
            }
          </div>
        }
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="p-field">
        <input
          [readonly]="isReadOnly"
          id="address2"
          name="address2"
          formControlName="address2"
          class="ui-inputtext form-control"
          placeholder="Apartment, Suite, etc. (optional)"
          maxlength="255"
          (focus)="$event.target.select()"
          data-testid="businessAddressAddress2"
          pInputText
        />
      </div>
    </div>
  </div>

  <div class="row" [class.cityDiv]="country?.value?.value !== 'United States' && country?.value?.value !== 'Canada'">
    <div class="col-12">
      <div
        class="p-field"
        [ngClass]="{
          'has-error': !city?.valid && (submitted || city?.touched)
        }"
      >
        <label for="City">City</label><br />
        <input
          [readonly]="isReadOnly"
          appTrimInput
          id="city"
          name="city"
          class="form-control"
          formControlName="city"
          placeholder="City"
          pInputText
          (focus)="$event.target.select()"
          maxlength="40"
          data-testid="businessAddressCity"
        />
        @if (submitted || city?.touched) {
          <div>
            @if (city.errors?.required || city.errors?.whitespace) {
              <div class="help-block pt-1">Required field</div>
            }
          </div>
        }
      </div>
    </div>
  </div>

  <div class="row" [class.zipCodeDiv]="country?.value?.value !== 'United States' && country?.value?.value !== 'Canada'">
    <div class="col-6">
      <div
        class="p-field"
        [ngClass]="{
          'has-error': (!postalCode?.valid || zipCodeBadInput) && (submitted || postalCode?.touched)
        }"
      >
        <label for="postalCode">Zip Code</label><br />
        <input
          [readonly]="isReadOnly"
          id="postalCode"
          name="postalCode"
          class="form-control"
          formControlName="postalCode"
          placeholder="Zip Code"
          pInputText
          (blur)="onBlurZipCode()"
          (focus)="$event.target.select()"
          [pattern]="zipCodePattern"
          maxlength="20"
          data-testid="businessAddressZipCode"
        />
        @if (submitted || postalCode?.touched) {
          <div>
            @if (postalCode.errors?.required || postalCode.errors?.whitespace) {
              <div class="help-block pt-1">Required field</div>
            }
            @if (!zipCodeBadInput && postalCode.errors?.pattern) {
              <div class="help-block pt-1">Invalid zip code format</div>
            }
            @if (zipCodeBadInput) {
              <div class="help-block pt-1">Invalid zip code</div>
            }
          </div>
        }
      </div>
    </div>

    @if (stateCombo) {
      <div
        class="col-6"
        [class.stateDiv]="country?.value?.value !== 'United States' && country?.value?.value !== 'Canada'"
      >
        <div
          class="p-field"
          [ngClass]="{
            'has-error': !state?.valid && (submitted || state?.touched)
          }"
        >
          <label for="state">State/Province</label><br />
          <p-select
            dropdownIcon="fa-solid fa-caret-down"
            class="withoutMarginDropdown stateSelect"
            [attr.disabled]="isReadOnly"
            id="state"
            name="state"
            formControlName="state"
            [options]="states"
            [required]="stateCombo"
            placeholder="State/Province"
          ></p-select>
          @if (submitted || state?.touched) {
            <div>
              @if (state.errors?.required || state.errors?.whitespace) {
                <div class="help-block pt-1">Required field</div>
              }
            </div>
          }
        </div>
      </div>
    }

    @if (!stateCombo) {
      <div
        class="col-6"
        [class.stateDiv]="country?.value?.value !== 'United States' && country?.value?.value !== 'Canada'"
      >
        <div
          class="p-field"
          [ngClass]="{
            'has-error': !stateText?.valid && (submitted || stateText?.touched)
          }"
        >
          <label for="State/Province">State/Province</label><br />
          <input
            id="stateText"
            name="stateText"
            class="form-control"
            formControlName="stateText"
            placeholder="Province or Region"
            pInputText
            [required]="!stateCombo"
            maxlength="40"
          />
          @if (submitted || stateText?.touched) {
            <div>
              @if (stateText.errors?.required || stateText.errors?.whitespace) {
                <div class="help-block pt-1">Required field</div>
              }
            </div>
          }
        </div>
      </div>
    }
  </div>
</ng-container>
