<div class="full-container-padding-side-15 p-t-xl p-b-xl scroll-content">
  <div class="detailsDetails">
    <div class="dspy-flex justifiedRow detailsHeading">
      <h4>
        <b data-testid="grantAccessTitle" class="title"> Grant access to {{ orgNameFolderName }}</b>
      </h4>
    </div>
    <div class="row medium">
      <div class="col-sm-12 header_wrapper">
        <div class="row p-t-sm p-b-sm align-items-center">
          <span class="selectuser">Select Users</span>
          <div class="col-sm-3 search_wrapper">
            <div class="pad-left-top" #searchControlDiv>
              <app-search-control
                id="primaryFrameSearchControl"
                [Configuration]="searchControlConfiguration"
                (SearchOccurred)="openSearchOverlay($event)"
              ></app-search-control>
              <p-overlayPanel #op appendTo="searchControlDiv" showCloseIcon="true">
                @if (isLoading) {
                  <div class="loading-section">
                    <rw-spinner
                      datapendoid="apphub-access-management-assign-spinner"
                      datatestid="apphub-access-management-assign-spinner"
                      spinnerclass="rw-spinner-primary"
                      size="sm"
                    >
                    </rw-spinner>
                    <span data-testid="loaderOverlay" class="loading"> Loading... </span>
                  </div>
                }
                <ng-template pTemplate>
                  <p-table
                    data-testid="searchResults"
                    [value]="rnGrantAccessSearchResultVM"
                    selectionMode="single"
                    (onRowSelect)="searchOccurred($event)"
                    [rows]="10"
                    class="search_table"
                  >
                    <ng-template pTemplate="body" let-rowData let-rnGrantAccessSearchResultVM>
                      <tr
                        [pSelectableRow]="rowData"
                        (click)="rowDataviewO(rowData, op)"
                        [pSelectableRowDisabled]="rnGrantAccessSearchResultVM?.User?.AvailableMappedDrivesCount === 0"
                      >
                        <td
                          tooltip-placement="right"
                          [ngClass]="{
                            'is-disabled': rnGrantAccessSearchResultVM?.User?.AvailableMappedDrivesCount === 0
                          }"
                          escape="false"
                          [escape]="false"
                          (click)="closeSearchOverlay()"
                        >
                          @if (rnGrantAccessSearchResultVM.SearchResultType === 'org') {
                            <span
                              escape="false"
                              [escape]="false"
                              pTooltip="<h3>
                        <strong>{{ rnGrantAccessSearchResultVM.Organization.Name }}</strong></h3>
                        <strong>Parent Account:</strong> {{ rnGrantAccessSearchResultVM.Organization.ParentName }}
                        <strong>Account Owner:</strong> {{ rnGrantAccessSearchResultVM.Organization.AccountOwner }}
                        <strong>Customer Since:</strong> {{
                                rnGrantAccessSearchResultVM.Organization.CreatedDate | date
                              }}
                        &nbsp;
                        <strong>Business Address</strong>
                        {{ rnGrantAccessSearchResultVM.Organization.Address.Address1 }}
                        {{ rnGrantAccessSearchResultVM.Organization.Address.Address2 }}
                        {{ rnGrantAccessSearchResultVM.Organization.Address.City }} {{
                                rnGrantAccessSearchResultVM.Organization.Address.StateProvince
                              }} {{ rnGrantAccessSearchResultVM.Organization.Address.PostalCodeID }}
                        {{ rnGrantAccessSearchResultVM.Organization.Address.Country }}"
                            >
                              <div class="data_row_wrapper">
                                <img
                                  src="../../../../../assets/images/rn-business-office.png"
                                  alt="icon"
                                  loading="lazy"
                                />
                                <div class="data_row_text">
                                  <span class="title">
                                    {{ rnGrantAccessSearchResultVM?.Organization?.Name }}
                                  </span>
                                  <span class="sub_title"> (all users) </span>
                                </div>
                                <span class="icon">+</span>
                              </div>
                            </span>
                          }
                          @if (
                            rnGrantAccessSearchResultVM.SearchResultType === 'user' &&
                            rnGrantAccessSearchResultVM.User.AvailableMappedDrivesCount !== 0
                          ) {
                            <span
                              escape="false"
                              [escape]="false"
                              pTooltip="<div>
                        <h3><strong>{{ rnGrantAccessSearchResultVM.User?.FullName }}</strong></h3>
                        <strong>Email Address:</strong> {{ rnGrantAccessSearchResultVM.User?.Email }}
                        <strong>Account:</strong> {{ rnGrantAccessSearchResultVM.User?.OrganizationName }}
                        <strong>Customer Since:</strong> {{ rnGrantAccessSearchResultVM.User?.CreatedDate | date }}
                        <strong>Role:</strong> {{ rnGrantAccessSearchResultVM.User?.UserType }}
                        </div>"
                            >
                              <div class="data_row_wrapper">
                                <div class="data_row_text">
                                  <span class="title">
                                    {{ rnGrantAccessSearchResultVM?.User?.FullName }}
                                  </span>
                                  <span class="sub_title">
                                    {{ rnGrantAccessSearchResultVM?.User?.Email }}
                                  </span>
                                </div>
                                <span class="icon">+</span>
                              </div>
                            </span>
                          }
                          @if (
                            rnGrantAccessSearchResultVM.SearchResultType === 'user' &&
                            rnGrantAccessSearchResultVM.User.AvailableMappedDrivesCount === 0
                          ) {
                            <span
                              escape="false"
                              [escape]="false"
                              pTooltip="<div>
                        <h3><strong>{{ rnGrantAccessSearchResultVM.User?.FullName }}</strong></h3>
                        <p style= 'color:red'><strong>Maximum Drive Mappings has been reached for this user.<br />To grant access to this user, a mapping must be removed from this user.</strong></p>
                        <strong>Email Address:</strong> {{ rnGrantAccessSearchResultVM.User?.Email }}
                        <strong>Account:</strong> {{ rnGrantAccessSearchResultVM.User?.OrganizationName }}
                        <strong>Customer Since:</strong> {{ rnGrantAccessSearchResultVM.User?.CreatedDate | date }}
                        <strong>Role:</strong> {{ rnGrantAccessSearchResultVM.User?.UserType }}
                        </div>"
                            >
                              <div class="data_row_wrapper">
                                <div class="data_row_text">
                                  <span class="title">
                                    {{ rnGrantAccessSearchResultVM?.User?.FullName }}
                                  </span>
                                  <span class="sub_title">
                                    {{ rnGrantAccessSearchResultVM?.User?.Email }}
                                  </span>
                                </div>
                                <span class="icon">+</span>
                              </div>
                            </span>
                          }
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </ng-template>
              </p-overlayPanel>
            </div>
          </div>
        </div>
      </div>
      <span class="table_badge">{{ dataSource?.length || '0' }} users</span>
    </div>
  </div>
  <br />
  <div class="p-datatable-wrapper user-table-container">
    <p-table
      id="userTable"
      [value]="userPageData"
      [responsive]="true"
      (onSort)="onSortUsers($event)"
      data-testid="resultsTable"
      class="search_table"
    >
      <ng-template pTemplate="header">
        <tr class="table-header-row">
          <th pSortableColumn="FullName">Name <p-sortIcon field="FullName"></p-sortIcon></th>
          <th pSortableColumn="Email">Email <p-sortIcon field="Email"></p-sortIcon></th>
          <th pSortableColumn="OrganizationName">Organization <p-sortIcon field="OrganizationName"></p-sortIcon></th>
          <th>Action</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-element let-i="rowIndex">
        <tr class="table-content-font user-row">
          <td>{{ element.FullName }}</td>
          <td>{{ element.Email }}</td>
          <td>{{ element.OrganizationName }}</td>
          <td class="remove-column">
            <mat-icon
              role="img"
              data-testid="removeBtn"
              aria-hidden="true"
              class="remove-button"
              data-mat-icon-type="font"
              (click)="removeAt(element.UserID)"
              >close</mat-icon
            >
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr class="empty-table-message">
          <td colspan="4" class="no_data" data-testid="noUsersText">No users added, yet</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <app-paginator
    [gridData]="dataSource"
    [sortData]="userTableSortData"
    (pageChanged)="userPageChanged($event)"
  ></app-paginator>
</div>
<div class="modal-footer copy-activation-link-footer">
  <app-button id="btnCancel" label="Cancel" buttonStyle="addUserTeritary" (clickActionEmitter)="dismiss()">
  </app-button>
  <app-button
    id="btnGrantAccess"
    buttonStyle="primary"
    [disabled]="userGrantAccessBtnDisabled"
    showProcessing="true"
    [processing]="isProcessing"
    label="Grant access"
    (clickActionEmitter)="grantAccess()"
  >
  </app-button>
</div>
