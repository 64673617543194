<div class="full-container-padding-side-15 licenseManagment">
  @if (isLoading) {
    <app-loader text="Loading..."></app-loader>
  }
  <div>
    <div class="flex">
      <span>
        @if (showHeaderLabel) {
          <span class="headerLabel">QuickBooks Desktop Licenses</span>
        }
      </span>
      <span>
        @if (WelcomeWizardMode) {
          <div>
            <div class="welcomeWizardSpecific">
              <span
                class="pull-right"
                pTooltip="Delete licenses"
                tooltipPosition="bottom"
                data-testid="tooltipDeleteLicensesBtn"
              >
                @if (canShowRemoveLicenseButton) {
                  <button
                    id="removeLicensesBtnWZ"
                    data-testid="btnRemoveLicenses"
                    [disabled]="selectionColumnConfig.SelectedItems.length <= 0"
                    class="button-spaced btn-icon"
                    (click)="removeLicensesConfirm()"
                  >
                    <i class="fa-regular fa-trash-alt licenseTrashIcon"></i>&nbsp;&nbsp;DELETE LICENSE&nbsp;&nbsp;
                  </button>
                }
              </span>
              <span class="pull-right">
                @if (canAddLicenses) {
                  <app-button
                    id="addLicenseBtn"
                    label="ADD LICENSE"
                    showIcon="true"
                    noContentPadding="true"
                    addSymbol="addSymbol"
                    buttonStyle="primaryUserModule"
                    (clickActionEmitter)="addLicense()"
                  >
                  </app-button>
                }
              </span>
            </div>
          </div>
        }
      </span>
      <span class="pull-right">
        @if (canAddLicenses && !WelcomeWizardMode) {
          <app-button
            id="addLicenseBtn"
            label="ADD LICENSE"
            showIcon="true"
            noContentPadding="true"
            addSymbol="addSymbol"
            buttonStyle="primaryUserModule"
            (clickActionEmitter)="addLicense()"
          >
          </app-button>
        }
      </span>
      <span
        class="pull-right"
        pTooltip="Delete licenses"
        tooltipPosition="bottom"
        data-testid="tooltipDeleteLicensesBtn"
      >
        @if (canShowRemoveLicenseButton && !WelcomeWizardMode) {
          <button
            id="removeLicensesBtn"
            data-testid="btnRemoveLicenses"
            [disabled]="selectionColumnConfig.SelectedItems.length <= 0"
            class="button-spaced btn-icon"
            (click)="removeLicensesConfirm()"
          >
            DELETE LICENSE
          </button>
        }
      </span>
    </div>
    <div class="flex">
      <span class="pull-left searchBox">
        <app-search-control
          id="licenseMgmtSearchControl"
          #licenseSearchControl
          [Configuration]="searchControlConfiguration"
          (SearchOccurred)="searchOccurred($event)"
          [clearEvent]="clearSearch.asObservable()"
          [searchText]="searchText"
        ></app-search-control>
      </span>
      <span class="pull-left exportButtonSpan">
        @if (showExportLicensesButton) {
          <app-button
            id="exportLicensesBtn"
            showIcon="false"
            iconClass="fa-regular fa-file-excel"
            [disabled]="exporting"
            buttonStyle="icon"
            (clickActionEmitter)="exportLicenses()"
            tooltip="<span data-testid='tooltipMsg'> Export all licenses</span>"
            tooltipsPosition="bottom"
          >
          </app-button>
        }
      </span>
    </div>
    <div data-testid="qbDesktopLicenseTable" class="flex-row license-list-container">
      <rn-grid class="flex-fill" [Configuration]="licenseListGridConfiguration"></rn-grid>
    </div>
  </div>

  @if (qboSubscriptionFeatureEnabled) {
    <div class="pad-bot-75">
      <div class="ui-widget">
        <div class="flex">
          <span>
            @if (showHeaderLabel) {
              <span class="headerLabel">QuickBooks Online Subscriptions</span>
            }
          </span>
          <span class="pull-right subscriptionButtons">
            @if (showPurchaseQBOsButton) {
              <app-button
                id="addQuickbooksSubsBtn"
                label="PURCHASE SUBSCRIPTION"
                noContentPadding="true"
                [disabled]="qboSubsCount && qboSubsCount > 0 && !qboMultipleSubscriptionFeatureEnabled"
                showIcon="false"
                buttonStyle="primaryUserModule"
                (clickActionEmitter)="purchaseQbo()"
                [tooltip]="getTooltipIfPurchaseNotAllowed()"
                tooltipsPosition="bottom"
              >
              </app-button>
            }
          </span>
        </div>
        <div class="flex">
          <span class="pull-left onlineSearchBox">
            <app-search-control
              id="quickbooksSubsSearchControl"
              #quickbooksSubsSearchControl
              [Configuration]="searchControlConfiguration"
              (SearchOccurred)="qboSearchOccurred($event)"
              [clearEvent]="clearSearch.asObservable()"
              [searchText]="searchTextQBO"
            ></app-search-control>
          </span>
          <span class="pull-left exportSubscriptionButtons">
            @if (showExportQBOsButton) {
              <app-button
                id="exportQuickbooksSubsBtn"
                showIcon="false"
                iconClass="fa-regular fa-file-excel"
                [disabled]="exporting"
                buttonStyle="icon"
                (clickActionEmitter)="exportQBOs()"
                tooltip="<span data-testid='tooltipMsg'>Export all subscriptions</span>"
                tooltipsPosition="bottom"
              >
              </app-button>
            }
          </span>
        </div>
        <table class="tid-table ui-table">
          <thead class="ui-table-thead table-heading">
            <tr class="tableHeading">
              <th class="toggleButtonCell"></th>
              <th data-testid="accountIDHeader" id="account-id">Account ID</th>
              <th data-testid="nameHeader" class="itemNameCell" id="name-id">Name</th>
              <th data-testid="typeHeader" id="type-id">Type</th>
              <th data-testid="subIDHead" id="subscription-id">Subscription ID</th>
              <th data-testid="manageHeader">Action</th>
            </tr>
          </thead>
          @for (subs of qboSubsData; track subs) {
            <tbody class="onlineQboTable">
              <tr>
                <td data-testid="accountID" class="toggleButtonCell" (click)="subs.showDetail = !subs.showDetail">
                  @if (!subs.showDetail && subs.Items?.length > 1) {
                    <app-icon source="rn-chevron-right"></app-icon>
                  }
                  @if (subs.showDetail && subs.Items?.length > 1) {
                    <app-icon source="rn-chevron-down"></app-icon>
                  }
                </td>
                <td data-testid="accountID" class="accountIdCell" (click)="subs.showDetail = !subs.showDetail">
                  {{ subs.AccountID }}
                </td>
                <td class="itemNameCell">
                  <div data-testid="name" class="name-value">
                    {{ subs.Items[0].Name }}
                  </div>
                </td>
                <td>
                  <div data-testid="type">{{ subs.Items[0].Type }}</div>
                </td>
                <td>
                  <div data-testid="subID">
                    {{ subs.Items[0].SubscriptionID }}
                  </div>
                </td>
                <td>
                  <mat-menu #appMenu="matMenu" class="custom-menu">
                    <app-button
                      id="addPayrollOrTime"
                      label="Add Payroll or Time"
                      noContentPadding="true"
                      iconClass="fa-solid fa-plus action-icon"
                      [disabled]="subs.Items.length !== 1"
                      showIcon="true"
                      buttonStyle="mat-menu-item"
                      (clickActionEmitter)="addPayrollOrTime(subs.AccountID)"
                      [tooltip]="getTooltipIfAddonPurchaseNotAllowed(subs.Items)"
                      tooltipsPosition="bottom"
                    >
                    </app-button>
                    <app-button
                      id="changeSubsciptionType"
                      label="Change Subscription Type"
                      noContentPadding="true"
                      iconClass="fa-solid fa-refresh action-icon"
                      showIcon="true"
                      buttonStyle="mat-menu-item"
                      (clickActionEmitter)="
                        changeSubscriptionType(subs.AccountID, subs.Items[0].SubscriptionID, subs.Items[0])
                      "
                    >
                    </app-button>
                    <app-button
                      id="removeAddon"
                      label="Cancel Subscription"
                      noContentPadding="true"
                      iconClass="fa-solid fa-times action-icon"
                      showIcon="true"
                      buttonStyle="mat-menu-item"
                      (clickActionEmitter)="cancelSubscription(subs.Items[0])"
                    >
                    </app-button>
                  </mat-menu>
                  <button mat-icon-button [matMenuTriggerFor]="appMenu" class="button-action">
                    <mat-icon class="margin-bot-10">more_horiz</mat-icon>
                  </button>
                </td>
              </tr>
              @for (item of subs.Items | slice: 1; track item; let i = $index) {
                <tr [ngClass]="{ 'hidden-details': !subs.showDetail }">
                  <td class="toggleButtonCell"></td>
                  <td></td>
                  <td class="itemNameCell">
                    <div data-testid="name" class="name-value">{{ item.Name }}</div>
                  </td>
                  <td>
                    <div data-testid="type">{{ item.Type }}</div>
                  </td>
                  <td>
                    <div data-testid="subID">{{ item.SubscriptionID }}</div>
                  </td>
                  <td>
                    <mat-menu #appMenu="matMenu" class="custom-menu">
                      <button
                        id="changeSubsciptionType"
                        mat-menu-item
                        (click)="changeSubscriptionType(subs.AccountID, subs.Items[0].SubscriptionID, item)"
                      >
                        <i class="fa-solid fa-refresh action-icon"></i
                        ><span class="action-text">Change Subscription Type</span>
                      </button>
                      <button id="removeAddon" mat-menu-item (click)="cancelSubscription(item)">
                        <i class="fa-solid fa-xmark action-icon" aria-hidden="true"></i
                        ><span class="action-text">Cancel Subscription</span>
                      </button>
                    </mat-menu>
                    <button mat-icon-button [matMenuTriggerFor]="appMenu" class="button-action">
                      <mat-icon class="margin-bot-10">more_horiz</mat-icon>
                    </button>
                  </td>
                </tr>
              }
            </tbody>
          }
        </table>
      </div>
      @if (qboSubsCount && qboSubsCount > 0) {
        <div class="paginator-container">
          <div class="left-section">
            <span data-testid="qbo">Rows per page&nbsp;</span>
            <select [(ngModel)]="qboPageSize" (change)="qboPageSizeChanged()" class="rows-option">
              @for (option of qboRowsPerPageOptions; track option) {
                <option [value]="option">
                  {{ option }}
                </option>
              }
            </select>
          </div>
          <div class="center-section">
            <span data-testid="qboGridPageButtonContainer" class="float-right">
              <div>
                <button
                  class="paginator-nav-button"
                  data-testid="qboGridNavButtonLeft"
                  [disabled]="qboCurrentPageNum === 0"
                  (click)="qboNavToPage(qboCurrentPageNum - 1)"
                >
                  <i class="fa-solid fa-chevron-left"></i>
                </button>
                <button
                  class="paginator-nav-button"
                  [disabled]="true"
                  [ngClass]="{ 'active-page-item': true }"
                  (click)="qboNavToPage(qboCurrentPageNum)"
                  [attr.data-testid]="'gridPageButton_1'"
                >
                  {{ qboCurrentPageNum + 1 }}
                </button>
                <button
                  class="paginator-nav-button"
                  data-testid="qboGridNavButtonRight"
                  [disabled]="qboCurrentPageNum + 1 >= qboTotalSubsCount / qboPageSize"
                  (click)="qboNavToPage(qboCurrentPageNum + 1)"
                >
                  <i class="fa-solid fa-chevron-right"></i>
                </button>
              </div>
              <div class="page-count">
                {{ qboCurrentPageNum * qboPageSize + 1 }}-{{
                  (qboCurrentPageNum + 1) * qboPageSize > qboTotalSubsCount
                    ? qboTotalSubsCount
                    : (qboCurrentPageNum + 1) * qboPageSize
                }}
                of {{ qboTotalSubsCount }}
              </div>
            </span>
          </div>
        </div>
      }
    </div>
  }
</div>
