<p-datepicker
  [minDate]="minDate"
  [maxDate]="maxDate"
  class="calendar-icon-override"
  [showIcon]="false"
  [(ngModel)]="selectedDate"
  (ngModelChange)="selectDate()"
  [placeholder]="placeholderText"
  [monthNavigator]="true"
  [yearNavigator]="true"
  [yearRange]="yearRange"
  dataType="string"
  ngDefaultControl
  [selectionMode]="selectionMode"
  [readonlyInput]="isReadonly"
  styleClass="date-field-style"
>
</p-datepicker>
