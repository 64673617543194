<div [formGroup]="timeForm">
  <p-accordion
    [(value)]="accordionValue"
    [expandIcon]="'pi pi-fw pi-chevron-down'"
    [collapseIcon]="'pi pi-fw pi-chevron-up'"
  >
    @for (tab of QboTimeData; track tab.SKU; let i = $index) {
      <div
        tabindex="0"
        (keyup.enter)="setRow(i)"
        [ngClass]="{
          'div-highlight': selectedIndex === i,
          'qbo-time': existingQboPlans === null || tab.PackageCategoryName.trim() !== existingQboPlans.Type.trim()
        }"
        (click)="setRow(i)"
      >
        @if (existingQboPlans === null || tab.PackageCategoryName.trim() !== existingQboPlans.Type.trim()) {
          <p-accordion-panel [value]="i" class="qboAccordionTab" (click)="selectionOccured(tab)">
            <p-accordion-header>
              <span class="package-name">{{ tab.PackageCategoryName }}</span>
              <span class="package-amount">
                <span class="float-right">
                  ${{ tab.NextBillPrice | number: '1.2-2' }}
                  <span class="month-label">/month</span>
                </span>
              </span>
            </p-accordion-header>
            <p-accordion-content>
              <p class="package-content" [innerHtml]="tab.HTMPDescription | safeHtml"></p>
            </p-accordion-content>
          </p-accordion-panel>
        }
      </div>
    }
    @if (existingQboPlans === null && !isChangeSubType) {
      <div
        tabindex="0"
        (keyup.enter)="selectionOccured(100)"
        class="div-class"
        [ngClass]="{ 'div-highlight': selectedIndex === 100 }"
        (click)="selectionOccured(100)"
      >
        I don’t need QuickBooks Time
      </div>
    }
  </p-accordion>
</div>
