<div [formGroup]="licenseSelectForm">
  <div class="row pb-4">
    <div class="col-12 pb-2">
      <label
        ><input
          name="isDeploy"
          class="licence-select-option-font"
          type="radio"
          formControlName="isDeploy"
          value="false"
          required
        />
        &nbsp; <b> No, I will take care of it later.</b></label
      ><br />
    </div>
    <div class="col-12 pb-2">
      <label
        ><input
          name="isDeploy"
          type="radio"
          class="licence-select-option-font"
          formControlName="isDeploy"
          value="true"
          required
        />
        &nbsp; <b> Yes, deploy now.</b></label
      >
    </div>
  </div>
  <div class="row">
    <div class="form-group col-12">
      <label for="qbVersion">Version</label>
      <fieldset [disabled]="isDeploy.value === 'false'">
        <p-select
          id="qbVersion"
          [options]="qbVersSelect"
          (onChange)="changeQbVersion()"
          dropdownIcon="fa-solid fa-caret-down"
          formControlName="qbVersion"
        >
        </p-select>
      </fieldset>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-12">
      <label for="country">Country</label>
      <fieldset [disabled]="isDeploy.value === 'false'">
        <p-select
          id="qbCountry"
          [options]="qbCountriesSelect"
          (onChange)="changeQbCountry()"
          dropdownIcon="fa-solid fa-caret-down"
          formControlName="qbCountry"
        >
        </p-select>
      </fieldset>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-12">
      <label for="version">Edition</label>
      <fieldset [disabled]="isDeploy.value === 'false'">
        <p-select
          id="qbEdition"
          [options]="qbEditionsSelect"
          (onChange)="changeQbEdition()"
          dropdownIcon="fa-solid fa-caret-down"
          formControlName="qbEdition"
        >
        </p-select>
      </fieldset>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-12">
      <label for="version">Flavor</label>
      <fieldset [disabled]="isDeploy.value === 'false'">
        <p-select
          id="qbFlavor"
          [options]="qbFlavorsSelect"
          (onChange)="changeQbFlavor()"
          dropdownIcon="fa-solid fa-caret-down"
          formControlName="qbFlavor"
          [overlayOptions]="getOverlayOptions()"
        >
        </p-select>
      </fieldset>
    </div>
  </div>
</div>
