import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { SelectItem } from "primeng/api";
import { Select } from "primeng/select";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subscription } from "rxjs";
import { AppInsightsService } from "../../../appInsights/app-insights.service";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";
import { RnErrorHandlerService } from "../../../services/rn-error-handler/rn-error-handler.service";
import { LicenseService } from "../../../services/rnapi2-service/apis/api";
import {
  RnLicenseBaseVM,
  RnLicenseFullVM,
  RnLicenseGetCatalog,
  RnLicensesCreate,
} from "../../../services/rnapi2-service/models/models";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { LicenseAddEditConfig } from "./license-add-edit-config";

@Component({
  selector: "app-license-add-edit",
  templateUrl: "./license-add-edit.component.html",
  styleUrls: ["./license-add-edit.component.scss"],
})
export class LicenseAddEditComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private licenseService: LicenseService,
    private appInsights: AppInsightsService,
    private toastService: RnToastService,
    private errorHandler: RnErrorHandlerService,
    private dialogTrackService: DialogTrackingService,
  ) {}

  public actionText: string;

  yearsSelect: SelectItem[] = [];
  countries: string[] = [];
  editionsSelect: SelectItem[] = [];
  @ViewChild("focus") dropdown: Select;

  public licenseKeyMask = "0000-0000-0000-000";
  public productNumberMask = "000-000";

  year = "";
  country = "US";
  edition = "";
  licenseQuantity = 1;
  licenseKey: string;
  productNumber: string;
  isProAdvisor = false;
  isAutoAssignable = true;
  canShowLicenseKeyAndProductNumber = true;
  canShowQuantity = false;
  canShowIsProAdvisor = false;
  canShowIsAutoAssignable = true;

  minQuantity = 1;
  maxQuantity = 100;

  loading = false;
  processing = false;

  ngOnInit(): void {
    this.licenseConfig = this.config.data as LicenseAddEditConfig;
    this.actionText = this.licenseConfig.IsEdit ? "Edit" : "Add";
    this.loading = true;
    const payload = new RnLicenseGetCatalog();
    payload.IncludeByol = true;
    payload.OrganizationId = this.licenseConfig.OrganizationID;
    this.subscriptions.push(
      this.licenseService
        .apiV2LicensesGetlicenseitemsPost(payload, "response")
        .subscribe((r) => {
          this.licenseCatalog = r.body.data.filter((l) => {
            return l.Type.indexOf("Annual") < 0;
          });
          const years = [];
          this.licenseCatalog.forEach((l) => {
            if (years.indexOf(l.Version) < 0) {
              years.push(l.Version);
            }
          });

          this.yearsSelect = this.createDropdownOptions(
            years.sort((a, b) => {
              return b.localeCompare(a);
            }),
          );
          this.loading = false;
        }),
    );
    this.canShowQuantity = this.licenseConfig.IsEdit;
  }

  ngAfterViewInit(): void {
    if (this.dropdown) {
      this.dropdown.applyFocus();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  cancel() {
    this.dialogTrackService.closeDialog(this.ref, false);
  }

  versionChange() {
    this.countries = [];
    this.licenseCatalog
      .filter((l) => {
        return l.Version === this.year;
      })
      .forEach((l) => {
        if (this.countries.indexOf(l.Country) < 0) {
          this.countries.push(l.Country);
        }
      });

    if (this.countries.indexOf(this.country) < 0) {
      this.country = "";
    }

    this.countryChange();
  }

  countryChange() {
    const editions = [];
    this.licenseCatalog.forEach((l) => {
      if (l.Version === this.year && l.Country === this.country) {
        if (editions.indexOf(l.Edition) < 0) {
          editions.push(l.Edition);
        }
      }
    });

    editions.sort();
    if (editions.indexOf(this.edition) < 0) {
      this.edition = "";
    }
    this.editionsSelect = this.createDropdownOptions(editions);
  }

  private createDropdownOptions(options): SelectItem[] {
    const selectOptions = options.map((o) => {
      return {
        label: o,
        value: o,
      };
    });
    selectOptions.unshift({ label: "", value: "" });
    return selectOptions;
  }

  submitChanges() {
    this.processing = true;
    const matches = this.licenseCatalog.filter((l) => {
      return (
        l.Version === this.year &&
        l.Country === this.country &&
        l.Edition === this.edition &&
        l.LicenseTypeName.toUpperCase() === "BYOL"
      );
    });
    const itemFlavor = matches[0];

    if (this.licenseConfig.IsEdit) {
      //Pending Edit
    } else {
      const createPayload = new RnLicensesCreate();
      createPayload.AffectedOrganizationId = this.licenseConfig.OrganizationID;
      createPayload.FlavorCountry = this.country;
      createPayload.OrganizationID = this.licenseConfig.OrganizationID;
      createPayload.LicenseTypeName = "BYOL";
      createPayload.FlavorID = itemFlavor.FlavorId;
      createPayload.ItemID = itemFlavor.ItemId;
      createPayload.isFSP = false;
      createPayload.LicenseData = new RnLicenseFullVM();
      createPayload.LicenseData.LicenseCount = -1;
      createPayload.LicenseData.License1 =
        this.licenseKey.replace(/-/g, "") || "NA";
      createPayload.LicenseData.License2 =
        this.productNumber.replace(/-/g, "") || "NA";
      createPayload.LicenseData.IsAutoAssignable = this.isAutoAssignable;
      createPayload.LicenseData.IsProAdvisor = this.isProAdvisor;
      createPayload.LicenseData.Version = this.year;
      createPayload.LicenseData.Country = this.country;
      createPayload.LicenseData.Edition = this.edition;
      createPayload.LicenseData.Flavor = "";
      createPayload.LicenseData.ItemId = itemFlavor.ItemId;
      createPayload.LicenseData.TypeId = itemFlavor.TypeId;

      this.subscriptions.push(
        this.licenseService
          .apiV2LicensesCreatePost(createPayload, "response")
          .subscribe((r) => {
            if (r && r.body && r.body.Success) {
              this.appInsights.logEvent("Add license - Added");
              this.toastService.showSuccess("License added successfully");
              this.dialogTrackService.closeDialog(this.ref, true);
            } else {
              const msgs = r && r.body ? r.body.Messages : null;
              this.errorHandler.handleError(
                "There was a problem adding the license.",
                msgs,
              );
              this.toastService.showSuccess(
                `<strong>There was a problem adding the license.<strong> Please <a href="${this.licenseConfig.OrgWLPSupportWebsite}">contact support</a> for further assistance.`,
              );
            }
          }),
      );
    }
  }

  private subscriptions: Subscription[] = [];
  private licenseCatalog: RnLicenseBaseVM[] = [];
  private licenseConfig: LicenseAddEditConfig;
}
